import { render, staticRenderFns } from "./notFind.vue?vue&type=template&id=bc7312c2&scoped=true&"
import script from "./notFind.vue?vue&type=script&lang=js&"
export * from "./notFind.vue?vue&type=script&lang=js&"
import style0 from "./notFind.vue?vue&type=style&index=0&id=bc7312c2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc7312c2",
  null
  
)

export default component.exports