<template>
  <div>
     <div>
      <div class="not_found_div">
        <img class="error-img" :src="icon['404']"/>
        <div class="msg">哎呀，打不开网页呢！</div>
        <div  class="to_index" @click="ToIndex">返回首页</div>
        <!-- <a @click="reloadPage" class="to_index link_cur">刷新页面</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import NOT_FOUND from '@/assets/error/404.png'
import SERVER_ERROR from '@/assets/error/500.png'
export default {
  name: "notFind",
  data: () => ({
        icon: {
      404: NOT_FOUND,
      500: SERVER_ERROR,
    },
  }),
  computed: {},
  methods: {
    reloadPage(){
      window.location.reload()
    },
    ToIndex(){
      this.$router.push({name:'home'})
    }
  },
}
</script>

<style scoped lang="less">
.not_found_div,
.server-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .error-img {
    width: 46.354vw;
    height: 24.21875vw;
  }

  .msg {
    color: #666;
    font-size: 1.5625vw;
    font-weight: 400;
    padding-bottom: 20px;
  }

  .to_index {
    width: 216px;
    height: 50px;
    background: #FA4A14;
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .to_index:hover {
    text-decoration: none;
  }
}
</style>
